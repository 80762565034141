<template>
    <v-container fluid
                 style="min-height: 0;"
                 grid-list-lg>

        <v-layout row wrap>

            <ListItems v-bind:liItem="{type: 'transaction',data: itemsMinusDeleted}" v-on:Edit="redirectToEditPage($event)" v-on:Delete="DeleteExpense($event)" v-on:clickedItem="itemclicked($event)"></ListItems>

        </v-layout>

    </v-container>
</template>

<script>
    import { globalFunctions } from '../../../../javascript/globalFunctions'

    export default {
        components: {
            ListItems: () => import('../../../listItem/listItems.vue')
        },
        props: ['budget', 'budgetBalance'],
        data() {
            return {
                items: [],
                itemsMinusDeleted: [],
            }
        },
        methods: {
            itemclicked: function (item) {
                /////////////////////////////////////
                //TO BE IMPLEMENTED
                /////////////////////////////////////
            },
            redirectToEditPage: function (item) {
                this.$router.push({ path: '/dashboard/edittransaction/' + item })
            },
            DeleteExpense: function (item) {
                /////////////////////////////////////
                //TO BE IMPLEMENTED
                /////////////////////////////////////
            },
            getItemsFromStoreAndRemovedDeletedItems: function () {
                let allItems = globalFunctions.stringify(this.$store.state.Transactions)

                for (let i = 0; i < allItems.length; i++) {
                    if (allItems[i].transactionType === 'expense') {
                        this.items.push(allItems[i])
                    }
                }

                this.removeDeletedExpensesFromItems()
            },
            removeDeletedExpensesFromItems: function () {
                this.itemsMinusDeleted = globalFunctions.stringify(this.items)

                for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
                    if (this.itemsMinusDeleted[i].objectState === 'Delete') {
                        this.itemsMinusDeleted.splice(i, 1)
                    }
                }
            },
        },
        watch: {
            "budgetBalance": function () {
                
            }
        },
        beforeMount() {
            this.getItemsFromStoreAndRemovedDeletedItems()
        }
    }
</script>